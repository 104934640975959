<template>
  <div>
    <Modal :options="{close:true}" @close="cerrar">
      <div class="title">{{ !etapatmp ? 'Agregar etapa' : 'Editar etapa' }}</div>
      <div class="body">
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Etapa</label>
          <div class="col-sm-10"><input v-model="etapa.etapa" type="text" name="etapa" id="etapa" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Origen</label>
          <div class="col-sm-10">
            <select v-model="etapa.aplicacion" name="origen" id="origen" class="form-control">
              <option v-for="origen in origenes.valores" :key="origen.id" :value="origen.codigo">{{ origen.nombre }}</option>
            </select>
          </div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Identificador</label>
          <div class="col-sm-10"><input v-model="etapa.identificador" type="text" name="identificador" id="identificador" class="form-control"></div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Tiempo máximo</label>
          <div class="col-sm-6">
            <div class="input-group">
              <select v-model="escala_tiempo_sel" name="catalogo_tiempo" id="catalogo_tiempo" class="custom-select col-sm-3">
                <option v-for="tiempo in escala_tiempo.valores" :key="tiempo.id" :value="tiempo.codigo">{{ tiempo.nombre }}</option>
              </select>
              <input v-model="tiempo_max_combinado" type="number" name="tiempo_max" id="tiempo_max" class="form-control">
            </div>
          </div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Inicio</label>
          <div class="col-sm-10">
            <select v-model="etapa.inicio" name="inicio" id="inicio" class="custom-select col-sm-2">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </select>
            <br><span class="tooltip-text">Indica que cuando una solicitud salte a esta etapa puede hacerla desde cualquier otra, sin importar el orden.</span>
          </div>
        </div>
        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Etapa final</label>
          <div class="col-sm-10">
            <select v-model="etapa.etapa_final" name="catalogo_tiempo" id="catalogo_tiempo" class="custom-select col-sm-2">
              <option value="1">Sí</option>
              <option value="0">No</option>
            </select>
            <br><span class="tooltip-text">Indica que la etapa es la última por lo cual al ingresar no hay mas contabilidad de tiempo</span>
          </div>
        </div>
        <!-- <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Depende de...</label>
          <div class="col-sm-10">
            <select v-model="etapa.depende_de" name="depende_de" id="depende_de" class="form-control">
              <option value="0">No depende de ninguna etapa</option>
              <option v-for="etapa in etapas" :key="etapa.id" :value="etapa.id">{{ etapa.etapa }}</option>
            </select>
          </div>
        </div> -->
        <div v-if="etapas.length > 0 && !etapatmp" class="row form-group">
          <label for="" class="col-form-label col-sm-2">Posición</label>
          <div class="col-sm-10">
            <div class="input-group">
              <div class="input-group-prepend"><span class="input-group-text">Despues de...</span></div>
              <select v-model="etapa.orden" name="orden" id="orden" class="form-control">
                <option v-for="etapa in etapas" :key="etapa.id" :value="etapa.orden">{{ etapa.etapa }}</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row form-group">
          <label for="" class="col-form-label col-sm-2">Eventos anclados</label>
          <div class="col-sm-10">
            <div class="row form-group">
              <div class="col-sm-3 offset-sm-9">
                <button class="btn secondary-btn" @click="abrir_evento_modal">Agregar evento</button>
              </div>
            </div>
            <div class="row form-group">
              <div class="col-sm-12">
                <table>
                  <thead>
                    <tr>
                      <th>Nombre</th>
                      <th>Tipo</th>
                      <th>Acción</th>
                      <th>Configuración</th>
                      <th>Estatus</th>
                      <th>Editar / Quitar</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(evento,index) in etapa.eventos" :key="index">
                      <td>{{ evento.nombre }}</td>
                      <td>{{ mutator_tipo(evento.tipo) }}</td>
                      <td>{{ evento.accion }}</td>
                      <td>{{ evento.configuracion }}</td>
                      <td>{{ mutator_estatus(evento.estatus) }}</td>
                      <td>
                        <img class="quitar_etapa mr-2" src="https://static.bienparabien.com/constelacion/img/edit.png" alt="Editar etapa" title="Editar etapa" @click="editar_evento(index)">
                        <img class="quitar_etapa" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar etapa" title="Elimitar etapa">
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn" @click="guardar">Guardar</button></div>
          <div class="col-sm-2"><button class="btn danger-btn" @click="cerrar">Cancelar</button></div>
        </div>
      </div>
    </Modal>

    <Modal v-if="modal_evento!=0" :options="{width: '40vw'}" @close="cerra_evento_modal">
      <form @submit.prevent="gaurdar_evento">
        <div class="title">{{modal_evento == 1 ? 'Agregar evento' : 'Editar evento' }}</div>
        <div class="body">
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Nombre</label>
            <div class="col-sm-10"><input v-model="evento.nombre" type="text" name="evento.nombre" id="evento.nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Tipo</label>
            <div class="col-sm-10">
              <select v-model="evento.tipo" name="evento.tipo" id="evento.tipo" class="form-control">
                <option v-for="tipo in catalogoTipos.valores" :key="tipo.id" :value="tipo.codigo">{{ tipo.nombre }}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Acción</label>
            <div class="col-sm-10">
              <select v-model="evento.accion" name="evento.accion" id="evento.accion" class="form-control">
                <option value="avanzar">Ingresar (ingresa a la etapa)</option>
                <option value="retroceder">Retroceder (retrocede a una etapa anterior)</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Configuración</label>
            <div class="col-sm-10">
              <textarea v-model="evento.configuracion" name="evento.configuracion" id="evento.configuracion" cols="30" rows="10" class="form-control" placeholder="Recuerda que debe ser en formato JSON..."></textarea>
            </div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-2">Estatus</label>
            <div class="col-sm-10">
              <select v-model="evento.estatus" name="evento.estatus" id="evento.estatus" class="form-control">
                <option value="1">Activo</option>
                <option value="0">Inactivo</option>
              </select>
            </div>
          </div>
        </div>
        <div class="footer">
          <div class="row">
            <div class="col-sm-2 offset-sm-8"><button class="btn secondary-btn">Guardar</button></div>
            <div class="col-sm-2"><button class="btn danger-btn" @click="cerra_evento_modal">Cancelar</button></div>
          </div>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import api from '@/apps/metricas/api/etapas'
  import apiCatalogos from '@/apps/metricas/api/catalogos'

  import Modal from '@/components/Modal'
  import InputC from '@/components/Form/InputCompuesto'

  export default {
    components: {
      Modal, InputC
    }
    ,props: {
      numetapas: 0
      ,etapas: null
      ,etapatmp: null
    }
    ,data: function() {
      return {
        etapa: {
          etapa: null
          ,aplicacion: null
          ,identificador: null
          ,tiempo_max: null
          ,inicio: 0
          ,orden: 0
          ,eventos: []
        }
        ,tiempo_max_formato: 0
        ,origenes: []
        ,catalogoTipos: {
          valores: []
        }
        ,modal_evento: 0
        ,evento: {
          nombre: null
          ,tipo: null
          ,configuracion: null
          ,estatus: 1
        }
        ,escala_tiempo: {
          valores: []
        }
        ,escala_tiempo_sel: 'minuto'
      }
    }
    ,mounted: async function() {
      // this.etapa.orden = this.numetapas;

      try {
        this.origenes = (await apiCatalogos.obtener_catalogo_codigo('apps')).data;
        this.etapa.origen = this.origenes.valores[0].codigo;

        this.escala_tiempo = (await apiCatalogos.obtener_catalogo_codigo('escala_tiempo')).data;
        this.$log.info('cargamos catalogo tiempos');
        this.escala_tiempo_sel = this.escala_tiempo.valores[0].codigo;
      }catch(e) {
        this.$log.info('err', e);
        this.$helper.showAxiosError(e,'Error');
      }
      
      this.etapa.orden = this.etapas[this.etapas.length-1].orden;

      if (this.etapatmp)
        this.etapa = this.etapatmp;

      if (!this.etapa.depende_de)
        this.etapa.depende_de = 0;

      setTimeout(() => {
        this.detectar_timpo_tiempo();
      },100);

      this.cargar_catalogo_tipos();
    }
    ,methods: {
      guardar: async function() {
        try {
          //Clonamos el objeto etapa
          let etapa = this.$helper.clone(this.etapa);
          this.calcular_segundos();

          if (!etapa.depende_de || etapa.depende_de == 0)
            delete etapa.depende_de;

          if (!this.etapatmp) {
            if (etapa.orden < this.etapas.length) {
              for(let i=1; i<=this.etapas.length; i++) {
                if (i>etapa.orden) {
                  let etapa = this.etapas[i-1];

                  if (!etapa.depende_de || etapa.depende_de == 0)
                    delete etapa.depende_de;

                  etapa.orden = etapa.orden+1;
                  await api.editar_etapa(etapa.id, etapa);
                }
              }
            }

            etapa.orden = etapa.orden+1;
            await api.crear_etapa(etapa);
            this.$helper.showMessage('Exito!','Se creo la etapa correctamente','success','alert');
          }else {
            await api.editar_etapa(etapa.id, etapa);
            this.$helper.showMessage('Exito!','Se guardo la etapa correctamente','success','alert');
          }
          this.$emit('cerrar');
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar: function() {
        this.$emit('cerrar')
      }
      ,cargar_catalogo_origenes: async function() {
        try {
          this.catalogoTipos = (await apiCatalogos.obtener_catalogo_codigo('tipos_eventos')).data;
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cargar_catalogo_tipos: async function() {
        try {
          this.catalogoTipos = (await apiCatalogos.obtener_catalogo_codigo('tipos_eventos')).data;
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerra_evento_modal: function() {
        this.evento = {
          nombre: null
          ,tipo: null
          ,configuracion: null
          ,estatus: 1
        }

        this.modal_evento = 0;
      }
      ,gaurdar_evento: async function() {
        if (this.modal_evento == 1)
          this.etapa.eventos.push(this.evento);
        else {
          let evento = this.evento;
          delete evento.index;
          this.etapa.eventos[this.evento.index] = evento;
        }


        this.cerra_evento_modal();
      }
      ,abrir_evento_modal: async function() {
        if (!this.catalogoTipos)
          await this.cargar_catalogo_tipos();

        this.evento.tipo = this.catalogoTipos.valores[0].codigo;
        this.modal_evento = 1;
      }
      ,mutator_estatus: function(val) {
        return val == 1 ? 'Activo' : 'Inactivo';
      }
      ,mutator_tipo: function(val) {
        let nombre = null;

        this.catalogoTipos.valores.forEach(valor => {
          if (val == valor.codigo)
            nombre = valor.nombre;
        })

        return nombre;
      }
      ,editar_evento: function(index) {
        this.evento = this.etapa.eventos[index];
        this.evento.index = index;
        this.modal_evento = 2;
      }
      ,detectar_timpo_tiempo: function() {
        let minutos = (this.etapa.tiempo_max / 60);
        let horas = Math.floor(minutos / 60);
        let dias = Math.floor(horas / 24);
        let semanas = Math.floor(dias / 7);

        this.$log.info('vemos el tipo de tiempo');
        
        if (dias >= 1)
          this.escala_tiempo_sel='dia';
        else if (horas >= 1)
          this.escala_tiempo_sel='hora';
        else
          this.escala_tiempo_sel='minuto';
      }
      ,calcular_segundos() {
        let val = parseInt(this.tiempo_max_formato);
        switch(this.escala_tiempo_sel) {
          case 'minuto':
            this.etapa.tiempo_max = val * 60;
              break;
          case 'hora':
            this.etapa.tiempo_max = val * (60 * 60);
              break;
          case 'dia':
            this.etapa.tiempo_max = val * ((60 * 60) * 24);
              break;
        }
      }
    }
    ,computed: {
      tiempo_max_combinado: {
        get: function() {
          if (!this.tiempo_max_formato || this.tiempo_max_formato == 0) {
            let minutos = Math.floor(parseInt(this.etapa.tiempo_max) / 60);
            let horas = Math.floor(minutos / 60);
            let dias = Math.floor(horas / 24);
            // let semanas = Math.floor(dias / 7);

            if (dias >= 1) {
              this.tiempo_max_formato = dias;
            }else if (horas >= 1) {
              this.tiempo_max_formato = horas;
            }else if (minutos >= 1) {
              this.tiempo_max_formato = minutos;
            }else {
              this.tiempo_max_formato = this.etapa.tiempo_max;
            }
          }

          return this.tiempo_max_formato;
        }
        ,set: function(val) {
          this.tiempo_max_formato = val;
          this.calcular_segundos();
        }
      }
    }
  }
</script>

<style lang="scss">
  .tooltip-text {
    color: #999999 !important;
  }
</style>